<!-- 基本信息管理 -->
<template>
	<div class="container">
		<baseTable
			ref="baseTable"
			@request="getTableData"
			@rowClick="rowClick"
			:queryItem="queryItem"
			:columns="tableCols"
			:otherBtns="otherBtns"
			custom
		/>
	</div>
</template>

<script>
import baseTable from '@/views/components/components-base-table';
import { getApprovalTypeOnce } from '@/api/requestOnce';
export default {
	name: 'entryManagement',
	props: { pageName: {} },
	components: { baseTable },
	computed: {
		applyTypeList() {
			return this.$store.state.approval?.applyTypeList || [];
		}
	},
	data() {
		return {
			queryItem: [
				{
					label: '姓名',
					key: 'staffName',
					type: 'input',
					span: 5
				},
				{
					label: '手机号',
					type: 'input',
					key: 'phoneNumber',
					span: 5
				}
			],
			tableCols: [
				{ label: '姓名', key: 'name' },
				{ label: '手机号', key: 'phoneNumber' },
				{ label: '组织', key: 'orgName' },
				{ label: '职位', key: 'officeName' },
				{ label: '岗位', key: 'jobName' },
				{
					label: '审批状态',
					key: 'approvalStatusName'
				}
			],
			otherBtns: [
				// this.permissionControlBtns(this.pageName, 'Export') && {
				// 	label: '导出',
				// 	icon: 'el-icon-download',
				// 	style: { float: 'right' },
				// 	click: () => {
				// 		// downloadByCreateA(`/v1/org/exportOrgEmployeePlan?params=${encodeURIComponent(JSON.stringify({ year, orgName }))}`);
				// 	}
				// },
				this.permissionControlBtns(this.pageName, 'ApprovalSettings') && {
					style: { float: 'right' },
					render: () => (
						<el-button
							size="small"
							icon="el-icon-s-order"
							type="primary"
							style="float: right;margin-right:10px"
							onclick={this.approvalSet}
						>
							基本信息修改申请
						</el-button>
					)
				}
			].filter((v) => v)
		};
	},

	mounted() {
		// 审批类型列表
		getApprovalTypeOnce();
	},

	methods: {
		getTableData(params, callback) {
			callback(this.$axios.post('/v1/org/findApprovalStaffBasicInfoVos', params));
		},
		approvalSet() {
			const data = this.applyTypeList.find(({ subType }) => subType === 20);

			if (data) {
				const { approvalId } = data;

				this.$router.push({
					path: '/staff/approvalApply',
					query: {
						subType: '20',
						approvalId
					}
				});
			}
		},
		rowClick(e) {
			if (e.mainId) {
				this.$router.push({
					path: '/staff/approval_details',
					query: {
						// id: e.mainId,
						// staffId: e.id,
						queryType: 3,
						subType: 20
					}
				});
			}

			// this.$router.push({
			// 	path: '/user_info',
			// 	query: {
			// 		id: e.id,
			// 		index: '11'
			// 	}
			// });
		}
		// returnStatusColor(status) {
		// 	// eslint-disable-next-line default-case
		// 	switch (status) {
		// 		case 1:
		// 			return '#64C940';
		// 		case 2:
		// 			return '#FF604D';
		// 		case 0:
		// 			return '#409EFF';
		// 	}
		// }
	}
};
</script>

<style lang="scss" scoped></style>
