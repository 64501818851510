import { render, staticRenderFns } from "./staffCareList.vue?vue&type=template&id=785e3fec&scoped=true&"
import script from "./staffCareList.vue?vue&type=script&lang=js&"
export * from "./staffCareList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "785e3fec",
  null
  
)

export default component.exports