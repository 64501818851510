<template>
	<div class="container">
		<div style="margin: 10px 0; width: 100%">
			<el-col :span="5" style="margin-right: 10px">
				<div class="inputBox">
					<span>姓名</span>
					<el-input v-model="params.name" size="small"></el-input>
				</div>
			</el-col>
			<el-col :span="5">
				<div class="inputBox">
					<span>工号</span>
					<el-input v-model="params.jobNumber" size="small"></el-input>
				</div>
			</el-col>
			<!-- <el-col :span="6"
              style="margin-right:15px">
        <div class="inputBox">
          <span style="width:50px">工号</span>
          <el-input v-model="params.jobNumber"></el-input>
      </div>-->
			<!-- </el-col> -->
			<el-col :span="9">
				<div class="inputBox">
					<span>晋升时间</span>
					<el-date-picker
						v-model="params.startTime"
						value-format="yyyy-MM-dd"
						format="yyyy-MM-dd"
						size="small"
						type="date"
						@change="startChange"
						style="margin-right: 5px; width: 350px"
					></el-date-picker
					>-
					<el-date-picker
						v-model="params.endTIme"
						value-format="yyyy-MM-dd"
						format="yyyy-MM-dd"
						size="small"
						type="date"
						@change="endChange"
						style="margin-left: 5px; width: 350px"
					></el-date-picker>
				</div>
			</el-col>
			<!-- <el-button
        type="primary"
        size="small"
        icon="el-icon-search"
        @click="handleSearch()"
      >查询</el-button> -->
			<div style="height: 42px; float: left">
				<el-button type="primary" size="small" icon="el-icon-search" style="height: 32px" @click="handleSearch()">查询</el-button>
			</div>
			<!-- <el-button @click="handleApply" size="small" type="primary" style="float: right">
				<i class="el-icon-s-order"></i>
				<span>申请</span>
			</el-button> -->
			<el-button
				icon="el-icon-s-order"
				size="small"
				type="primary"
				style="float: right; margin-left: 20px"
				@click="approvalSet"
				v-if="permissionControlBtns(pageName, 'ApprovalSettings')"
			>
				晋升申请
			</el-button>
		</div>
		<!--<div class="toolBox" style="float:right;width:36%">
      <div>
         <el-button type="primary"
                   size="medium "
                   style="margin-left: 10px;margin-top: 5px;"
        @click="dialogImportVisible=true">导入</el-button>
        <el-button
          type="primary"
          size="small"
          style="margin-left: 10px"
          icon="el-icon-download"
          @click="
          "
        >导出</el-button>
      </div>
    </div>!-->
		<div style="width: 100%">
			<el-table
				:data="tableData.list"
				slot="empty"
				class="tableBackground"
				@row-click="handleEditClick"
				v-loading="loadding"
				style="width: 100%"
			>
				<!-- <el-table-column prop="staffId"
                         label="序号">
        </el-table-column>-->
				<!-- <el-table-column type="index" label="序号" align="center" :width="80" :index="indexMethod"></el-table-column> -->
				<el-table-column prop="name" align="center" label="姓名"></el-table-column>
				<el-table-column prop="jobNumber" align="center" label="工号"></el-table-column>
				<el-table-column prop="phoneNumber" align="center" label="手机号码"></el-table-column>
				<el-table-column prop="orgName" align="center" label="部门"></el-table-column>
				<el-table-column prop="officeName" align="center" label="职位"></el-table-column>
				<el-table-column prop="jobName" align="center" label="岗位"></el-table-column>
				<!-- <el-table-column prop="level" align="center" label="职级"></el-table-column> -->
				<!-- <el-table-column prop="officeCode" align="center" label="职级代码"></el-table-column> -->
				<!-- <el-table-column prop="orgName" align="center" label="组织名称"></el-table-column> -->
				<el-table-column prop="entryTime" align="center" label="入职日期"></el-table-column>
				<el-table-column prop="updateTime" align="center" label="晋升日期"></el-table-column>
				<!--<el-table-column label="操作"
                         align="center"
                         width="130px">
          <template v-slot="{row}">
            <el-dropdown>
              <el-button type="text">
                ...
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(item, i) in commands"
                                  @click.native.stop="handleEditClick(row)"
                                  :command="item"
                                  :key="i">{{item.label}}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>!-->
			</el-table>
		</div>

		<div style="width: 100%; float: right">
			<el-pagination
				class="fr"
				style="margin: 20px 0"
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="pagenation.pageNo"
				:page-sizes="[20, 50, 100, 200, 300, 400]"
				:page-size="pagenation.pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="pagenation.totalNum"
			></el-pagination>
		</div>
		<importmodal :dialogImportVisible.sync="dialogImportVisible" :importUrl="importUrl" :type="type"></importmodal>
	</div>
</template>

<script>
import * as getDatas from '@/api/workbench';
import importmodal from '@/components/import';
import { setUrlParams } from '@/utils/util';
import { getApprovalTypeOnce } from '@/api/requestOnce';
export default {
	name: 'reward',
	components: {
		importmodal
	},
	computed: {
		applyTypeList() {
			return this.$store.state.approval?.applyTypeList || [];
		}
	},
	props: {
		index: { type: Number, default: () => 0 },
		pageName: {}
	},
	data() {
		return {
			type: 'promotion',
			// 分页参数
			pagenation: {
				pageNo: 1,
				pageSize: 20,
				totalNum: 0
			},
			// levelList: [],
			// 搜索条件对象
			params: {
				promotionType: '0',
				name: '',
				// jobNumber:'',
				startTime: '',
				endTime: '',
				pageNo: 1,
				pageSize: 20
			},
			tableData: [],
			tabIndex: '',
			loadding: false,
			dialogVisible: false,
			labelPosition: 'right',
			rowId: 0,
			// rankList: [],
			dialogImportVisible: false,
			importUrl: '/v1/org/importStaffPromotionInfo',
			commands: [
				{
					label: '详情',
					value: '1'
				}
			]
		};
	},
	created() {},
	mounted() {
		this.$nextTick(() => {
			// this.rankList = this.$store.state.setData.rankList;
			this.handleSearch();
			this.tabIndex = this.index;
			getApprovalTypeOnce();
		});
	},
	methods: {
		// 排序
		indexMethod(index) {
			return index + (this.pagenation.pageNo - 1) * this.pagenation.pageSize + 1;
		},
		startChange(val) {
			this.params.startTime = `${val} 00:00:00`;
		},
		endChange(val) {
			this.params.endTime = `${val} 23:59:59`;
		},
		handleSearch() {
			this.loadding = true;

			const params = {
				request: this.params
			};

			getDatas
				.queryStaffPromotionInfo(params)
				.then((result) => {
					this.tableData = result;
					this.pagenation.totalNum = result.totalNum;
					this.loadding = false;
				})
				.catch(() => {});
		},
		// 详情
		handleEditClick(row) {
			this.rowId = row.id;
			// this.$router.push({
			// 	path: '/promotion_edit',
			// 	query: {
			// 		id: this.rowId,
			// 		index: this.tabIndex,
			// 		name: '晋升详情'
			// 	}
			// });
			if (row.mainId) {
				this.$router.push({
					path: '/staff/approval_details',
					query: {
						id: row.mainId,
						staffId: row.id,
						queryType: 3,
						approvalTypeName: '晋升',
						subType: 6
					}
				});
			}
		},
		// handleInfoClick (row) {
		//   this.rowId = row.staffId
		//   sessionStorage.setItem("secondTabIndex", 'third');
		//   this.$router.push({
		//     path: "/user_info",
		//     query: {
		//       id: this.rowId,
		//       index: this.tabIndex
		//     }
		//   });
		// },
		// 获取管理等级
		// handleChange () {
		//   this.params.codeName = this.$refs.cascader.getCheckedNodes()[0].pathLabels[2];
		//   this.params.codeId = this.$refs.cascader.getCheckedNodes()[0].value;
		// },
		handleSizeChange(val) {
			this.pagenation.pageSize = val;
			this.params.pageSize = val;
			this.handleSearch();
		},
		handleCurrentChange(val) {
			this.pagenation.pageNo = val;
			this.params.pageNo = val;
			this.handleSearch();
		},
		// 导出
		outPort() {
			const outParams = {
				name: this.params.name,
				codeId: this.params.codeId,
				type: this.params.type,
				phoneNumber: this.params.phoneNumber,
				jobNumber: this.params.jobNumber,
				updateTimeBefore: this.params.updateTimeBefore,
				updateTimeEnd: this.params.updateTimeEnd
			};

			const outStr = encodeURIComponent(JSON.stringify(outParams));

			const url = `/v1/org/exportStaffPromotionInfo?params=${outStr}`;

			window.open(document.location.origin + url);
		},
		// 申请
		handleApply() {
			this.$router.push({
				path: '/humanaffairs/apply',
				query: { params: setUrlParams({ type: 'promotion' }) }
			});
		},
		approvalSet() {
			const data = this.applyTypeList.find(({ subType }) => subType === 6);

			if (data) {
				const { subType, approvalId } = data;

				this.$router.push({
					path: '/staff/approval_apply?mode=申请审核',
					query: {
						subType,
						approvalId
					}
				});
			}
		}
	}
};
</script>
<style lang="scss" scoped>
.container {
	display: flex;
	flex-wrap: wrap;
}
.toolBox {
	width: 100%;
	position: absolute;
	top: 10px;
	right: 0;
	margin-bottom: 10px;
	div {
		float: right;
	}
}
// .inputBox {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin-bottom: 10px;
//   span {
//     width: 100px;
//     text-align: right;
//     margin-right: 10px;
//   }
// }
</style>
<style lang="scss">
.textarea {
	width: 100%;
	.el-form-item__content {
		width: 80%;
	}
}
</style>
