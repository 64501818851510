<template>
	<div class="container">
		<!--<div class="toolBox"></div>!-->
		<div style="margin: 10px 0">
			<el-col :span="4">
				<div class="inputBox">
					<span>姓名</span>
					<el-input v-model="params.name" size="small" style="width: 178px"></el-input>
				</div>
			</el-col>
			<el-col :span="4">
				<div class="inputBox">
					<span>职位</span>
					<el-input v-model="params.officeName" placeholder="请输入职位名称" size="small"></el-input>
				</div>
			</el-col>
			<el-col :span="5">
				<div class="inputBox">
					<span>离职类型</span>
					<el-select size="small" v-model="params.dimissionType" placeholder="请选择离职类型">
						<el-option v-for="item in leveloptions" :key="item.dictId" :label="item.dictName" :value="item.dictId"> </el-option>
					</el-select>
				</div>
			</el-col>
			<el-col :span="8">
				<div class="inputBox">
					<span>离职日期</span>
					<el-date-picker
						v-model="params.dimissionDateStart"
						value-format="yyyy-MM-dd"
						format="yyyy-MM-dd"
						size="small"
						type="date"
						@change="startChange"
						style="margin-right: 5px; width: 250px"
					></el-date-picker
					>-
					<el-date-picker
						v-model="params.dimissionDateEnd"
						value-format="yyyy-MM-dd"
						format="yyyy-MM-dd"
						type="date"
						size="small"
						@change="endChange"
						style="margin-left: 5px; width: 250px"
					></el-date-picker>
				</div>
			</el-col>
			<el-button
				type="primary"
				size="small"
				style="height: 32px; margin-right: 10px; margin-bottom: 10px"
				icon="el-icon-search"
				@click="handleSearch()"
				>查询</el-button
			>
			<div style="display: inline-flex; float: right">
				<el-button
					icon="el-icon-s-order"
					size="small"
					type="primary"
					style="float: right"
					@click="approvalSet"
					v-if="permissionControlBtns(pageName, 'ApprovalSettings')"
				>
					离职申请
				</el-button>
				<el-button size="small" type="primary" style="float: right" @click="reasonSet" v-if="permissionControlBtns(pageName, 'ConfigQuit')">
					离职原因配置
				</el-button>
			</div>
		</div>
		<div style="width: 100%">
			<el-table
				:data="tableData.list"
				class="tableBackground"
				slot="empty"
				@row-click="handleRowClick"
				v-loading="loadding"
				style="width: 100%"
			>
				<el-table-column type="index" label="序号" :width="80" align="center" :index="indexMethod"></el-table-column>
				<el-table-column
					v-for="(cols, index) in tableCols"
					:key="index"
					:prop="cols.values"
					:label="cols.name"
					align="center"
				></el-table-column>
				<!--<el-table-column align="center"
                         label="操作"
                         width="130px">
          <template v-slot="{row}">
            <el-dropdown>
              <el-button type="text">
                ...
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(item, i) in commands"
                                  @click.native.stop="handleRowClick(row,item)"
                                  :command="item"
                                  :key="i">{{item.label}}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>!-->
			</el-table>
		</div>

		<div style="width: 100%; float: right">
			<el-pagination
				class="fr"
				style="margin: 20px 0"
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="pagenation.pageNo"
				:page-sizes="[20, 50, 100, 200, 300, 400]"
				:page-size="pagenation.pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="pagenation.totalNum"
			></el-pagination>
		</div>
	</div>
</template>

<script>
import { getDimissonList } from '@/api/workbench';
import { getPositionLevelList } from '../../api/staffSystem';
// import { setUrlParams } from '@/utils/util';
import { getApprovalTypeOnce } from '@/api/requestOnce';
export default {
	name: 'reward',
	props: {
		pageName: {}
	},
	components: {},
	computed: {
		applyTypeList() {
			return this.$store.state.approval?.applyTypeList || [];
		}
	},
	data() {
		return {
			// 分页参数
			pagenation: {
				pageNo: 1,
				pageSize: 20,
				totalNum: 0
			},
			// 搜索条件对象
			params: {
				name: '',
				office: '',
				officeName: '',
				dimissionDateStart: '',
				dimissionDateEnd: '',
				dimissionType: '',
				pageNo: 1,
				pageSize: 20
			},
			tableData: [],
			tableCols: [
				// {name: '序号',values:'id'},
				{ name: '姓名', values: 'name' },
				{ name: '工号', values: 'jobNumber' },
				{ name: '手机号码', values: 'phoneNumber' },
				{ name: '聘用形式', values: 'hireFormName' },
				{ name: '部门', values: 'orgName' },
				{ name: '职位', values: 'officeName' },
				{ name: '入职日期', values: 'entryTime' },
				{ name: '预计离职时间', values: 'dimissionDate' },
				{ name: '实际离职时间', values: 'affirmDimissionDate' },
				// { name: '离职日期', values: 'dimissionDate' },
				{ name: '离职类型', values: 'dimissionTypeName' },
				{ name: '离职原因', values: 'reason' }
			],
			tabIndex: '',
			loadding: false,
			positionOpt: [],
			rowId: 0,
			leveloptions: [],
			commands: [
				{
					label: '详情',
					value: '1'
				}
			]
		};
	},
	created() {
		this.leveloptions = this.$store.state.app.dict.filter((item) => item.groupId == 'leaveType');
	},
	mounted() {
		// this.getPositions();
		this.$nextTick(() => {
			this.handleSearch();
			getApprovalTypeOnce();
		});
	},
	methods: {
		getPositions() {
			getPositionLevelList({
				pageNo: 1,
				pageSize: 2000
			}).then((res) => {
				if (res._responseStatusCode === 0) {
					this.positionOpt = res.list;
				}
			});
		},
		// 排序
		indexMethod(index) {
			return index + (this.pagenation.pageNo - 1) * this.pagenation.pageSize + 1;
		},
		startChange(val) {
			this.params.dimissionDateStart = `${val} 00:00:00`;
		},
		endChange(val) {
			this.params.dimissionDateEnd = `${val} 23:59:59`;
		},
		handleSearch() {
			this.loadding = true;
			const params = {
				request: this.params
			};

			getDimissonList(params)
				.then((result) => {
					this.tableData = result;
					this.pagenation.totalNum = result.totalNum;
					this.loadding = false;
				})
				.catch(() => {});
		},
		handleRowClick(row) {
			this.rowId = row.id;
			// this.$router.push({
			// 	path: '/dimission_info',
			// 	query: {
			// 		id: this.rowId
			// 	}
			// });
			if (row.mainId) {
				this.$router.push({
					path: '/staff/approval_details',
					query: {
						id: row.mainId,
						staffId: row.id,
						queryType: 3,
						approvalTypeName: '离职',
						subType: 4
					}
				});
			}
		},
		handleSizeChange(val) {
			this.pagenation.pageSize = val;
			this.params.pageSize = val;
			this.handleSearch();
		},
		handleCurrentChange(val) {
			this.pagenation.pageNo = val;
			this.params.pageNo = val;
			this.handleSearch();
		},
		approvalSet() {
			const data = this.applyTypeList.find(({ subType }) => subType === 4);

			if (data) {
				const { subType, approvalId } = data;

				this.$router.push({
					path: '/staff/approval_apply?mode=申请审核',
					query: {
						subType,
						approvalId
					}
				});
			}
		},
		reasonSet() {
			this.$router.push({
				path: '/humanaffairs/quit'
			});
		}
	}
};
</script>
<style lang="scss" scoped>
.container {
	display: flex;
	flex-wrap: wrap;
}
.toolBox {
	width: 100%;
	margin: 20px;
	div {
		float: right;
	}
}
</style>
<style lang="scss">
.textarea {
	width: 100%;
	.el-form-item__content {
		width: 80%;
	}
}
</style>
