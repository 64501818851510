<template>
	<div class="app-container">
		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="员工生日" name="1" v-if="permissionControl('EmployeeBirthday')">
				<birthmanage ref="staffCareList1" :activeTab="activeName" v-if="activeName === '1'" pageName="EmployeeBirthday" />
			</el-tab-pane>
			<el-tab-pane label="入职周年纪念日" name="2" v-if="permissionControl('Anniversary')">
				<birthmanage ref="staffCareList2" :activeTab="activeName" v-if="activeName === '2'" pageName="Anniversary" />
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import birthmanage from '@/components/humanafairs/birthManage';
// import orgConfig from '../orgConfig.vue';
export default {
	name: 'staffCareList',
	components: { birthmanage },
	props: { pageName: {} },
	data() {
		return { activeName: '' };
	},

	mounted() {
		const data = JSON.parse(sessionStorage.getItem('staffCareListData'));
		const { activeName, listQuery } = data || {};

		this.activeName = activeName || '1';
		if (listQuery) {
			this.listQuery = listQuery;
		}
		// 设置tab默认
		this.setActiveTab(this.pageName, [
			{ name: '1', key: 'EmployeeBirthday' },
			{ name: '2', key: 'Anniversary' }
		]);
	},
	methods: {
		handleClick() {
			sessionStorage.setItem(
				'staffCareListData',
				JSON.stringify({
					activeName: this.activeName
				})
			);
		}
	}
};
</script>

<style lang="scss" scoped></style>
