<template>
	<div class="container">
		<div class="toolBox">
			<div>
				<!-- <el-button
          type="primary"
          size="medium "
          style="margin-left: 10px;margin-top: 5px;"
        >导入</el-button>-->
			</div>
		</div>
		<div style="margin: 0 0 10px; width: 100%">
			<el-col :span="5">
				<div class="inputBox">
					<span>姓名</span>
					<el-input v-model="params.name" size="small"></el-input>
				</div>
			</el-col>
			<el-col :span="5">
				<div class="inputBox">
					<span>组织</span>
					<treeselect
						:props="depProps"
						:options="deps"
						:value="depsId"
						:clearable="isClearable"
						:accordion="isAccordion"
						:level="depsId"
						@getValue="getValue($event)"
						style="width: 100%"
					/>
				</div>
			</el-col>
			<el-col :span="9">
				<div class="inputBox" v-if="this.activeTab === '1'">
					<span>生日日期</span>
					<el-select v-model="params.birTimeBefore" @change="beforeDateChange" size="small">
						<el-option v-for="item in monthList" :key="item.label" :value="item.id" :label="item.label"></el-option>
					</el-select>
					-
					<el-select v-model="params.birTimeEnd" size="small">
						<el-option
							v-for="item in monthList1"
							:disabled="item.disabled"
							:key="item.label"
							:value="item.id"
							:label="item.label"
						></el-option>
					</el-select>
				</div>
				<div class="inputBox" v-if="this.activeTab === '2'">
					<span>入职时间</span>
					<el-select v-model="params.anniversariesBefore" @change="beforeDateChange" size="small">
						<el-option v-for="item in monthList" :key="item.label" :value="item.id" :label="item.label"></el-option>
					</el-select>
					-
					<el-select v-model="params.anniversariesEnd" size="small">
						<el-option
							v-for="item in monthList1"
							:disabled="item.disabled"
							:key="item.label"
							:value="item.id"
							:label="item.label"
						></el-option>
					</el-select>
				</div>
			</el-col>
			<el-button type="primary" size="small" icon="el-icon-search" @click="handleSearch()">查询</el-button>
			<el-button
				type="primary"
				size="small"
				icon="el-icon-download"
				@click="exprotList"
				style="float: right"
				v-if="permissionControlBtns(pageName, 'Export')"
				>导出</el-button
			>
		</div>
		<div style="width: 100%">
			<el-table :data="tableData.list" slot="empty" v-loading="loadding" style="width: 100%" class="tableBackground">
				<el-table-column type="index" label="序号" :width="80" align="center" :index="indexMethod"></el-table-column>
				<el-table-column
					v-for="(cols, index) in tableCols"
					:key="index"
					:prop="cols.values"
					:label="cols.name"
					align="center"
				></el-table-column>
			</el-table>
		</div>

		<div style="width: 100%; float: right">
			<el-pagination
				class="fr"
				style="margin: 20px 0"
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="pagenation.pageNo"
				:page-sizes="[20, 50, 100, 200, 300, 400]"
				:page-size="pagenation.pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="pagenation.totalNum"
			></el-pagination>
		</div>
	</div>
</template>

<script>
import * as getDatas from '@/api/workbench';
import treeselect from '@/components/treeSelect/treeSelect';
export default {
	name: 'reward',
	components: {
		treeselect
	},

	props: {
		index: { type: String, default: () => '' },
		activeTab: { type: String },
		pageName: { type: String }
	},
	data() {
		return {
			isClearable: true, // 可清空（可选）
			isAccordion: true, // 可收起（可选）
			// 分页参数
			pagenation: {
				pageNo: 1,
				pageSize: 20,
				totalNum: 0
			},
			depProps: {
				value: 'id',
				label: 'orgName',
				children: 'children'
			},
			// 搜索条件对象
			params: {
				name: '',
				orgId: '',
				birTimeBefore: '',
				birTimeEnd: '',
				pageNo: 1,
				pageSize: 20
			},
			tableData: [],
			tableCols: [
				// {name: '序号',values:'staffId'},
				{ name: '姓名', values: 'name' },
				{ name: '工号', values: 'jobNumber' },
				this.activeTab === '1' && { name: '年龄', values: 'age' },
				this.activeTab === '1' && { name: '生日', values: 'birthday' },
				{ name: '员工性质', values: 'hireFormName' },
				{ name: '组织', values: 'orgName' },
				{ name: '职位', values: 'officeName' },
				this.activeTab === '2' && { name: '入职时间', values: 'entryTime' },
				this.activeTab === '2' && { name: '工龄', values: 'workingYears' }
			].filter((v) => v),
			tabIndex: '',
			loadding: false,
			labelPosition: 'right',
			rowId: 0,
			deps: this.$store.state.setData.depsList,
			depsId: '',
			monthList: [
				{ label: '1月', id: '1' },
				{ label: '2月', id: '2' },
				{ label: '3月', id: '3' },
				{ label: '4月', id: '4' },
				{ label: '5月', id: '5' },
				{ label: '6月', id: '6' },
				{ label: '7月', id: '7' },
				{ label: '8月', id: '8' },
				{ label: '9月', id: '9' },
				{ label: '10月', id: '10' },
				{ label: '11月', id: '11' },
				{ label: '12月', id: '12' }
			],
			monthList1: [
				{ label: '1月', id: '1' },
				{ label: '2月', id: '2' },
				{ label: '3月', id: '3' },
				{ label: '4月', id: '4' },
				{ label: '5月', id: '5' },
				{ label: '6月', id: '6' },
				{ label: '7月', id: '7' },
				{ label: '8月', id: '8' },
				{ label: '9月', id: '9' },
				{ label: '10月', id: '10' },
				{ label: '11月', id: '11' },
				{ label: '12月', id: '12' }
			]
		};
	},
	computed: {
		userInfo() {
			let str = sessionStorage.getItem('userInfo');

			if (str) {
				str = JSON.parse(str);
			} else {
				str = {};
			}
			return str;
		}
	},
	created() {},
	mounted() {
		this.$nextTick(() => {
			const before = new Date().getMonth() + 1;

			if (this.activeTab === '1') {
				this.params = {
					...this.params,
					birTimeBefore: `${before}`,
					birTimeEnd: `${before + 1 > 12 ? 12 : before + 1}`
				};
			} else {
				this.params = {
					...this.params,
					anniversariesBefore: `${before}`,
					anniversariesEnd: `${before + 1 > 12 ? 12 : before + 1}`
				};
			}
			this.monthList1 = this.monthList1.map((val) => {
				if (Number(val.id) < Number(before)) {
					val.disabled = true;
				} else {
					val.disabled = false;
				}
				return val;
			});
			this.handleSearch();
		});
	},
	methods: {
		beforeDateChange(e) {
			if (e) {
				this.params.birTimeEnd = '';
				this.params.anniversariesEnd = '';
				this.monthList1 = this.monthList1.map((val) => {
					if (Number(val.id) < Number(e)) {
						val.disabled = true;
					} else {
						val.disabled = false;
					}
					return val;
				});
			}
		},
		// 排序
		indexMethod(index) {
			return index + (this.pagenation.pageNo - 1) * this.pagenation.pageSize + 1;
		},
		exprotList() {
			const { params } = this;

			const outStr = encodeURIComponent(JSON.stringify(params));

			const url = `/v1/org/specialQueryStaffBirInfo/list/export?params=${outStr}`;

			window.open(document.location.origin + url);
		},
		// startChange(val){
		//   this.params.birTimeBefore = val + " 00:00:00"

		// },
		// endChange(val){
		//   this.params.birTimeEnd = val + " 23:59:59"
		// },
		handleSearch() {
			this.loadding = true;
			const isadmin = ['1', '2'].includes(this.userInfo.userType);

			const params = {
				request: this.params
			};

			if (isadmin) {
				getDatas
					.queryStaffBirInfo(params)
					.then((result) => {
						this.tableData = result;
						this.pagenation.totalNum = result.totalNum;
						this.loadding = false;
					})
					.catch(() => {});
			} else {
				getDatas
					.StaffBirInfo(params)
					.then((result) => {
						this.tableData = result;
						this.pagenation.totalNum = result.totalNum;
						this.loadding = false;
					})
					.catch(() => {});
			}
		},

		// 部门选中的值
		getValue(eve) {
			if (eve) {
				this.depsId = eve.id;
				this.params.orgId = eve.id;
			} else {
				this.depsId = '';
				this.params.orgId = '';
			}
		},

		handleSizeChange(val) {
			this.pagenation.pageSize = val;
			this.params.pageSize = val;
			this.handleSearch();
		},
		handleCurrentChange(val) {
			this.pagenation.pageNo = val;
			this.params.pageNo = val;
			this.handleSearch();
		}
	}
};
</script>
<style lang="scss" scoped>
.container {
	display: flex;
	flex-wrap: wrap;
}
.toolBox {
	width: 100%;
	margin-bottom: 10px;
	div {
		float: right;
	}
}
</style>
<style lang="scss">
.textarea {
	width: 100%;
	.el-form-item__content {
		width: 80%;
	}
}
</style>
