<template>
	<div class="container">
		<div class="toolBox"></div>
		<div style="margin-top: 10px; width: 100%">
			<el-col :span="5">
				<div class="inputBox">
					<span>姓名</span>
					<el-input v-model="params.name" size="small"></el-input>
				</div>
			</el-col>
			<el-col :span="5">
				<div class="inputBox">
					<span>工号</span>
					<el-input v-model="params.jobNumber" size="small"></el-input>
				</div>
			</el-col>
			<el-col :span="9">
				<div class="inputBox">
					<span>调动时间</span>
					<el-date-picker
						v-model="params.transferTimeBefore"
						value-format="yyyy-MM-dd"
						format="yyyy-MM-dd"
						type="date"
						size="small"
						@change="startChange"
						style="margin-right: 5px; width: 250px"
					></el-date-picker
					>-
					<el-date-picker
						v-model="params.transferTimeEnd"
						value-format="yyyy-MM-dd"
						format="yyyy-MM-dd"
						type="date"
						size="small"
						@change="endChange"
						style="margin-left: 5px; width: 250px"
					></el-date-picker>
				</div>
			</el-col>
			<div style="height: 42px; float: left">
				<el-button type="primary" size="small" icon="el-icon-search" style="height: 32px" @click="handleSearch()">查询</el-button>
			</div>
			<!-- <el-button @click="handleApply" size="small" type="primary" style="float: right">
				<i class="el-icon-s-order"></i>
				<span>申请</span>
			</el-button> -->
			<el-button
				icon="el-icon-s-order"
				@click="approvalSet"
				size="small"
				type="primary"
				style="float: right"
				v-if="permissionControlBtns(pageName, 'ApprovalSettings')"
			>
				调动申请
			</el-button>
		</div>
		<div style="width: 100%">
			<el-table
				:data="tableData.list"
				slot="empty"
				class="tableBackground"
				@row-click="handleRowClick"
				v-loading="loadding"
				style="width: 100%; margin-top: 15px"
			>
				<!-- <el-table-column type="index" label="序号" :width="80" align="center" :index="indexMethod"></el-table-column> -->
				<el-table-column
					v-for="(cols, index) in tableCols"
					:key="index"
					:prop="cols.values"
					:label="cols.name"
					align="center"
				></el-table-column>
				<!--<el-table-column label="操作"
                         align="center"
                         width="130px">
          <template v-slot="{row}">
            <el-dropdown>
              <el-button type="text">
                ...
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(item, i) in commands"
                                  @click.native.stop="handleRowClick(row,item)"
                                  :command="item"
                                  :key="i">{{item.label}}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>!-->
			</el-table>
		</div>

		<div style="width: 100%; float: right">
			<el-pagination
				class="fr"
				style="margin: 20px 0"
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="pagenation.pageNo"
				:page-sizes="[20, 50, 100, 200, 300, 400]"
				:page-size="pagenation.pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="pagenation.totalNum"
			></el-pagination>
		</div>
		<!-- <approvalSetDialog
			:visible="approvalSetVisible"
			:subType="dialogData.subType"
			@close="approvalSetVisible = false"
			:activeType="dialogData.active"
			:approvalInfoId="dialogData.approvalInfoId"
			haveForm
		/> -->
	</div>
</template>

<script>
import { getTransferList } from '@/api/workbench';
import { setUrlParams } from '@/utils/util';
import { getApprovalTypeOnce } from '@/api/requestOnce';
// import approvalSetDialog from '../../views/organizingpersonnel/organizing/organizational/approval/approvalSetDialog.vue';
export default {
	name: 'reward',
	components: {},
	props: { pageName: {} },
	computed: {
		applyTypeList() {
			return this.$store.state.approval?.applyTypeList || [];
		}
	},
	data() {
		return {
			// 分页参数
			pagenation: {
				pageNo: 1,
				pageSize: 20,
				totalNum: 0
			},
			// 搜索条件对象
			params: {
				name: '',
				jobNumber: '',
				transferTimeBefore: '',
				transferTimeEnd: '',
				pageNo: 1,
				pageSize: 20
			},
			tableData: [],
			tableCols: [
				{ name: '姓名', values: 'name' },
				{ name: '工号', values: 'jobNumber' },
				{ name: '手机号码', values: 'phoneNumber' },
				{ name: '部门', values: 'orgName' },
				{ name: '职位', values: 'officeName' },
				{ name: '岗位', values: 'jobName' },
				{ name: '入职日期', values: 'entryTime' },
				{ name: '调动日期', values: 'createTime' }
			],
			tabIndex: '',
			loadding: false,
			rowId: 0,
			commands: [
				{
					label: '详情',
					value: '1'
				}
			],
			dialogData: {
				subType: '3',
				approvalInfoId: 1525
			}
		};
	},
	created() {
		// this.handleSearch();
	},
	mounted() {
		this.$nextTick(() => {
			this.handleSearch();
			// 审批类型列表
			getApprovalTypeOnce();
		});
	},
	methods: {
		// 排序
		indexMethod(index) {
			return index + (this.pagenation.pageNo - 1) * this.pagenation.pageSize + 1;
		},
		startChange(val) {
			this.params.transferTimeBefore = `${val} 00:00:00`;
		},
		endChange(val) {
			this.params.transferTimeEnd = `${val} 23:59:59`;
		},
		handleSearch() {
			this.loadding = true;
			const params = {
				request: this.params
			};

			getTransferList(params)
				.then((result) => {
					this.tableData = result;
					this.pagenation.totalNum = result.totalNum;
					this.loadding = false;
				})
				.catch(() => {
					this.loadding = false;
				});
		},
		// 调动详情
		handleRowClick(row) {
			this.rowId = row.id;
			// this.$router.push({
			// 	path: '/promotion_edit',
			// 	query: {
			// 		id: this.rowId,
			// 		name: '调岗详情'
			// 	}
			// });
			if (row.mainId) {
				this.$router.push({
					path: '/staff/approval_details',
					query: {
						id: row.mainId,
						staffId: row.id,
						queryType: 3,
						approvalTypeName: '调动',
						subType: 3
					}
				});
			}
		},
		handleSizeChange(val) {
			this.pagenation.pageSize = val;
			this.params.pageSize = val;
			this.handleSearch();
		},
		handleCurrentChange(val) {
			this.pagenation.pageNo = val;
			this.params.pageNo = val;
			this.handleSearch();
		},
		handleApply() {
			this.$router.push({
				path: '/humanaffairs/apply',
				query: { params: setUrlParams({ type: 'transfer' }) }
			});
		},
		approvalSet() {
			const data = this.applyTypeList.find(({ subType }) => subType === 3);

			if (data) {
				const { subType, approvalId } = data;

				this.$router.push({
					path: '/staff/approval_apply?mode=申请审核',
					query: {
						subType,
						approvalId
					}
				});
			}
		}
	}
};
</script>
<style lang="scss" scoped>
.container {
	display: flex;
	flex-wrap: wrap;
}
.toolBox {
	position: absolute;
	top: 42px;
	right: 0;
	margin-bottom: 10px;
	div {
		float: right;
	}
}
// .inputBox {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin-bottom: 10px;
//   span {
//     width: 100px;
//     text-align: right;
//     margin-right: 10px;
//   }
// }
</style>
<style lang="scss">
.textarea {
	width: 100%;
	.el-form-item__content {
		width: 80%;
	}
}
</style>
