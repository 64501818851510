<template>
	<div class="container">
		<div class="toolBox">
			<!-- <div>
      <el-button
          type="primary"
          size="medium "
          icon="el-icon-plus"
          style="margin-left: 10px;margin-top: 5px;"
          @click="openDialog"
        >奖惩录入</el-button>
      </div>-->
		</div>
		<el-row style="margin: 0 0 10px; width: 100%">
			<el-col :span="5">
				<div class="inputBox">
					<span>姓名</span>
					<el-input v-model="params.name" size="small"></el-input>
				</div>
			</el-col>
			<el-col :span="5">
				<div class="inputBox">
					<span>工号</span>
					<el-input v-model="params.jobNumber" size="small"></el-input>
				</div>
			</el-col>
			<el-col :span="5">
				<div class="inputBox">
					<span>奖惩类型</span>
					<el-select v-model="params.type" size="small">
						<el-option label="奖励" value="1"></el-option>
						<el-option label="惩罚" value="2"></el-option>
					</el-select>
				</div>
			</el-col>
			<el-col :span="9">
				<div class="inputBox">
					<span>奖惩日期</span>
					<el-date-picker
						v-model="params.startTime"
						value-format="yyyy-MM-dd"
						format="yyyy-MM-dd"
						type="date"
						size="small"
						@change="startChange"
						style="margin-right: 5px; width: 250px"
					></el-date-picker
					>-
					<el-date-picker
						v-model="params.endTime"
						value-format="yyyy-MM-dd"
						format="yyyy-MM-dd"
						type="date"
						size="small"
						@change="endChange"
						style="margin-left: 5px; width: 250px"
					></el-date-picker>
				</div>
			</el-col>
			<!-- <el-button type="primary" size="small" icon="el-icon-search" @click="handleSearch()">查询</el-button> -->
			<div style="height: 42px; float: left">
				<el-button type="primary" size="small" icon="el-icon-search" style="height: 32px" @click="handleSearch()">查询</el-button>
			</div>
			<!-- <el-button @click="handleApply" size="small" type="primary" style="float: right">
				<i class="el-icon-s-order"></i>
				<span>申请</span>
			</el-button> -->
			<el-button
				icon="el-icon-s-order"
				size="small"
				type="primary"
				style="float: right; margin-left: 20px"
				@click="approvalSet"
				v-if="permissionControlBtns(pageName, 'ApprovalSettings')"
			>
				申请奖惩
			</el-button>
		</el-row>
		<div style="width: 100%">
			<el-table
				class="tableBackground"
				:data="tableData.list"
				slot="empty"
				@row-click="handleRowClick"
				v-loading="loadding"
				style="width: 100%"
			>
				<el-table-column type="index" label="序号" :width="80" align="center" :index="indexMethod"></el-table-column>
				<el-table-column v-for="(cols, index) in tableCols" :key="index" min-width="20" :prop="cols.values" :label="cols.name" align="center">
					<div
						slot-scope="{ row }"
						v-if="cols.values == 'reason'"
						style="max-height: 90px; word-break: break-word; overflow: hidden; text-overflow: ellipsis"
					>
						{{ row[cols.values] && row[cols.values].length > 60 ? row[cols.values].substring(0, 56) + '...' : row[cols.values] }}
					</div>
					<div slot-scope="{ row }" v-else>
						{{ row[cols.values] }}
					</div>
				</el-table-column>
				<!-- <el-table-column align="center" label="操作" width="130px">
					<template slot-scope="scope">
						<el-button @click.stop="handleDel(scope.row)" type="text" size="small">删除</el-button>
						<el-button @click="handleRowClick(scope.row)" type="text" size="small">详情</el-button>
					</template>
				</el-table-column> -->
			</el-table>
		</div>

		<div style="width: 100%; float: right">
			<el-pagination
				class="fr"
				style="margin: 20px 0"
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="pagenation.pageNo"
				:page-sizes="[20, 50, 100, 200, 300, 400]"
				:page-size="pagenation.pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="pagenation.totalNum"
			></el-pagination>
		</div>
		<el-dialog title="奖惩录入" :visible.sync="dialogVisible">
			<el-form
				:model="addParams"
				ref="addParams"
				:rules="rules"
				:label-position="labelPosition"
				label-width="120px"
				:inline="true"
				class="demo-form-inline"
			>
				<el-col :span="12" v-show="editStatus">
					<el-form-item label="员工" prop="staffId">
						<el-select style="width: 100%" v-model="addParams.staffId">
							<el-option v-for="(item, index) in staffList" :key="index" :label="item.staffName" :value="item.staffId"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="类型" prop="type">
						<el-select style="width: 100%" v-model="addParams.type">
							<el-option v-for="(item, index) in punishTypeList" :key="index" :label="item.dictName" :value="item.dictId"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="原因" class="textarea" prop="reason">
						<el-input style="width: 100%" v-model="addParams.reason" show-word-limit maxlength="255" type="textarea" rows="4"></el-input>
					</el-form-item>
				</el-col>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="addReward">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import * as getDatas from '@/api/workbench';
import { setUrlParams } from '@/utils/util';
import { getApprovalTypeOnce } from '@/api/requestOnce';
export default {
	name: 'reward',
	components: {},
	computed: {
		applyTypeList() {
			return this.$store.state.approval?.applyTypeList || [];
		}
	},
	props: {
		index: { type: String, default: () => '' },
		pageName: {}
	},
	data() {
		return {
			// 分页参数
			pagenation: {
				pageNo: 1,
				pageSize: 20,
				totalNum: 0
			},
			// 搜索条件对象
			params: {
				name: '',
				startTime: '',
				endTime: '',
				pageNo: 1,
				pageSize: 20
			},
			tableData: [],
			tableCols: [
				// {name: '序号',values:'id'},
				{ name: '姓名', values: 'name' },
				{ name: '工号', values: 'jobNumber' },
				{ name: '部门', values: 'orgName' },
				{ name: '职位', values: 'officeName' },
				{ name: '奖惩类型', values: 'typeName' },
				{ name: '奖惩内容', values: 'content' },
				{ name: '奖惩原因', values: 'reason' },
				{ name: '奖惩日期', values: 'createTime' }
			],
			tabIndex: '',
			loadding: false,
			dialogVisible: false,
			addParams: {
				staffId: null,
				type: null,
				reason: ''
			},
			labelPosition: 'right',
			staffList: this.$store.state.setData.principalList,
			punishTypeList: [],
			rowId: 0,
			rules: {
				staffId: [{ required: true, message: '请选择员工', trigger: 'change' }],
				type: [{ required: true, message: '请选择类型', trigger: 'change' }],
				reason: [{ required: true, message: '请输入原因', trigger: 'blur' }]
			},
			editStatus: true
		};
	},
	created() {
		// this.handleSearch();
	},
	mounted() {
		this.$nextTick(() => {
			this.punishTypeList = this.$store.state.app.dict.filter((item) => item.groupId == 'rewardPunishType');
			this.handleSearch();
			getApprovalTypeOnce();
		});
	},
	methods: {
		// 排序
		indexMethod(index) {
			return index + (this.pagenation.pageNo - 1) * this.pagenation.pageSize + 1;
		},
		startChange(val) {
			this.params.startTime = `${val} 00:00:00`;
		},
		endChange(val) {
			this.params.endTime = `${val} 23:59:59`;
		},
		openDialog() {
			this.dialogVisible = true;
			this.editStatus = true;
			if (this.$refs['addParams']) {
				this.$refs['addParams'].clearValidate();
				this.$refs['addParams'].resetFields();
			}

			this.addParams = {
				staffId: null,
				type: null,
				reason: ''
			};
		},
		editReward(row) {
			if (this.$refs['addParams']) {
				this.$refs['addParams'].clearValidate();
				this.$refs['addParams'].resetFields();
			}

			this.addParams = {
				staffId: null,
				type: null,
				reason: ''
			};
			this.editStatus = false;
			this.dialogVisible = true;
			this.addParams.staffId = row.id;
			this.addParams.type = row.type;
			this.addParams.reason = row.reason;
		},
		handleSearch() {
			this.loadding = true;
			const params = {
				request: this.params
			};

			getDatas
				.queryRewardList(params)
				.then((result) => {
					this.tableData = result;
					this.pagenation.totalNum = result.totalNum;
					this.loadding = false;
				})
				.catch(() => {});
		},
		// 新增奖惩
		addReward() {
			this.$refs['addParams'].validate((valid) => {
				if (valid) {
					const params = {
						request: this.addParams
					};

					getDatas
						.addReward(params)
						.then((result) => {
							if (result.code == 0) {
								this.handleSearch();
								this.dialogVisible = false;
							}
						})
						.catch(() => {});
				}
			});
		},
		// 详情
		handleRowClick(row) {
			this.rowId = row.staffId;
			// this.$router.push({
			// 	path: '/reward_info',
			// 	query: {
			// 		id: this.rowId
			// 	}
			// });
			if (row.mainId) {
				this.$router.push({
					path: '/staff/approval_details',
					query: {
						id: row.mainId,
						staffId: row.id,
						queryType: 3,
						approvalTypeName: '奖惩',
						subType: 5
					}
				});
			}
		},
		handleSizeChange(val) {
			this.pagenation.pageSize = val;
			this.params.pageSize = val;
			this.handleSearch();
		},
		handleCurrentChange(val) {
			this.pagenation.pageNo = val;
			this.params.pageNo = val;
			this.handleSearch();
		},
		handleApply() {
			this.$router.push({
				path: '/humanaffairs/apply',
				query: { params: setUrlParams({ type: 'reward' }) }
			});
		},
		// 删除
		handleDel(row) {
			this.$confirm('是否确定删除?', '删除提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.$axios
						.post('/v1/org/staff/reward/punish/del', {
							request: { id: row.id }
						})
						.then((res) => {
							if (res && res.code === 0) {
								this.$message.success('删除成功!');
								this.handleSearch();
							} else {
								// this.$message.error(res.message)
							}
						});
				})
				.catch((error) => {
					console.log(error);
				});
		},
		approvalSet() {
			const data = this.applyTypeList.find(({ subType }) => subType === 5);

			if (data) {
				const { subType, approvalId } = data;

				this.$router.push({
					path: '/staff/approval_apply?mode=申请审核',
					query: {
						subType,
						approvalId
					}
				});
			}
		}
	}
};
</script>
<style lang="scss" scoped>
.container {
	display: flex;
	flex-wrap: wrap;
}
.toolBox {
	width: 100%;
	margin-bottom: 10px;
	div {
		float: right;
	}
}
// .inputBox{
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin-bottom: 10px;
//     span{
//       width: 100px;
//       text-align: right;
//       margin-right: 10px
//     }
// }
</style>
<style lang="scss">
.textarea {
	width: 100%;
	.el-form-item__content {
		width: 80%;
	}
}
</style>
