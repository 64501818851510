<template>
	<div class="app-container">
		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="基本信息管理" name="9" v-if="permissionControl('BaseInfoManagement')">
				<baseInfoManagement
					ref="employeeRelations9"
					:index="2"
					:activeTab="activeName"
					v-if="activeName === '9'"
					pageName="BaseInfoManagement"
				/>
			</el-tab-pane>
			<el-tab-pane label="入职管理" name="1" v-if="permissionControl('InductionManagement')">
				<entryManagement
					ref="employeeRelations1"
					:index="2"
					:activeTab="activeName"
					v-if="activeName === '1'"
					pageName="InductionManagement"
				/>
			</el-tab-pane>
			<el-tab-pane label="转正管理" name="2" v-if="permissionControl('EmploymentConfirmationManagement')">
				<positive ref="employeeRelations2" :activeTab="activeName" v-if="activeName === '2'" pageName="EmploymentConfirmationManagement" />
			</el-tab-pane>
			<el-tab-pane label="调动管理" name="3" v-if="permissionControl('TransferManagement')">
				<transfer ref="employeeRelations3" :activeTab="activeName" v-if="activeName === '3'" pageName="TransferManagement" />
			</el-tab-pane>
			<el-tab-pane label="晋升管理" name="4" v-if="permissionControl('PromotionManagement')">
				<promotion ref="employeeRelations4" :activeTab="activeName" v-if="activeName === '4'" pageName="PromotionManagement" />
			</el-tab-pane>
			<el-tab-pane label="离职管理" name="5" v-if="permissionControl('LeaveManagement')">
				<dimisson ref="employeeRelations5" :activeTab="activeName" v-if="activeName === '5'" pageName="LeaveManagement" />
			</el-tab-pane>
			<el-tab-pane label="奖惩管理" name="6" v-if="permissionControl('RewardPunishmentManagement')">
				<reward ref="employeeRelations6" :activeTab="activeName" v-if="activeName === '6'" pageName="RewardPunishmentManagement" />
			</el-tab-pane>
			<!-- <el-tab-pane label="调薪管理" name="7" v-if="permissionControl('/v1/org/staff/reward/punish/list')">
				<salaryAdjustment ref="employeeRelations7" :activeTab="activeName" v-if="activeName === '7'" />
			</el-tab-pane> -->
			<el-tab-pane label="员工关怀" name="8" v-if="permissionControl('EmployeeCare')">
				<staffCareList ref="employeeRelations8" :activeTab="activeName" v-if="activeName === '8'" pageName="EmployeeCare" />
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import entryManagement from './entryManagement';
import baseInfoManagement from './baseInfoManagement';
// import salaryAdjustment from './salaryAdjustment';
import positive from '@/components/humanafairs/positive'; // 转正
import transfer from '@/components/humanafairs/transfer'; // 调动
import promotion from '@/components/humanafairs/promotion'; // 晋升
import dimisson from '@/components/humanafairs/dimission'; // 离职
import reward from '@/components/humanafairs/reward'; // 奖惩
import staffCareList from './staffCareList'; // 关怀
// import orgConfig from '../orgConfig.vue';
export default {
	name: 'employeeRelations',
	components: { entryManagement, baseInfoManagement, transfer, positive, promotion, dimisson, reward, staffCareList },

	data() {
		return { activeName: '' };
	},

	mounted() {
		const data = JSON.parse(sessionStorage.getItem('employeeRelationsData'));
		const { activeName, listQuery } = data || {};

		this.activeName = activeName || '1';
		if (listQuery) {
			this.listQuery = listQuery;
		}
		// 设置tab默认
		this.setActiveTab(this.$route.name, [
			{ name: '1', key: 'InductionManagement' },
			{ name: '2', key: 'EmploymentConfirmationManagement' },
			{ name: '3', key: 'TransferManagement' },
			{ name: '4', key: 'PromotionManagement' },
			{ name: '5', key: 'LeaveManagement' },
			{ name: '6', key: 'RewardPunishmentManagement' },
			{ name: '8', key: 'EmployeeCare' },
			{ name: '9', key: 'BaseInfoManagement' }
		]);
	},
	methods: {
		handleClick() {
			sessionStorage.setItem(
				'employeeRelationsData',
				JSON.stringify({
					activeName: this.activeName
				})
			);
		}
	}
};
</script>

<style lang="scss" scoped></style>
