<template>
	<div class="container">
		<div class="toolBox"></div>
		<div style="margin-bottom: 15px">
			<el-col :span="5">
				<div class="inputBox">
					<span>姓名</span>
					<el-input v-model="params.name" size="small"></el-input>
				</div>
			</el-col>
			<el-col :span="5">
				<div class="inputBox">
					<span>职位</span>
					<el-input v-model="params.officeName" size="small" placeholder="请输入职位名称"></el-input>
				</div>
			</el-col>
			<el-col :span="5">
				<div class="inputBox">
					<span>转正状态</span>
					<el-select v-model="params.status" placeholder="请选择转正状态" size="small" clearable>
						<el-option v-for="item in statusoptions" :key="item.dictId" :label="item.dictName" :value="item.dictId"> </el-option>
					</el-select>
				</div>
			</el-col>
			<el-col :span="9">
				<div class="inputBox">
					<span>入职日期</span>
					<el-date-picker
						v-model="params.entryTimeStart"
						value-format="yyyy-MM-dd"
						format="yyyy-MM-dd"
						size="small"
						type="date"
						@change="startChange"
						style="margin-right: 5px; width: 250px"
					></el-date-picker
					>-
					<el-date-picker
						v-model="params.entryTimeEnd"
						value-format="yyyy-MM-dd"
						format="yyyy-MM-dd"
						size="small"
						type="date"
						@change="endChange"
						style="margin-left: 5px; width: 250px"
					></el-date-picker>
				</div>
			</el-col>
			<el-button
				icon="el-icon-s-order"
				size="small"
				type="primary"
				style="float: right; margin-left: 20px"
				@click="approvalSet"
				v-if="permissionControlBtns(pageName, 'ApprovalSettings')"
			>
				转正申请
			</el-button>
			<el-button type="primary" size="small" style="height: 32px; float: right" icon="el-icon-search" @click="handleSearch()">查询</el-button>
		</div>
		<div style="width: 100%">
			<el-table :data="tableData.list" slot="empty" class="tableBackground" @row-click="rowClick" v-loading="loadding" style="width: 100%">
				<el-table-column type="index" label="序号" align="center" :width="80" :index="indexMethod"></el-table-column>
				<el-table-column
					v-for="(cols, index) in tableCols"
					:key="index"
					:prop="cols.values"
					:label="cols.name"
					align="center"
				></el-table-column>
				<!-- <el-table-column align="center" label="操作">
					<template v-slot="{ row }">
						<el-button type="text" @click.stop="(e) => handleEidt(e, row)">编辑</el-button>
					</template>
				</el-table-column> -->
				!
			</el-table>
		</div>
		<el-dialog
			:title="title"
			top="30vh"
			center
			:visible="dialogVisible"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			class="form_dialog_style"
			width="550px"
		>
			<el-form :model="formModel" :rules="rules" ref="configForm" label-width="110px">
				<el-form-item label="转正日期" prop="turnPositiveTime">
					<el-date-picker
						v-model="formModel.turnPositiveTime"
						placeholder="请选择转正日期"
						clearable
						value-format="yyyy-MM-dd"
					></el-date-picker>
				</el-form-item>
				<el-form-item label="转正状态" prop="status">
					<el-select v-model="formModel.status" placeholder="请选择转正状态" @change="statusChange" clearable>
						<el-option v-for="it in statusOpts" :key="it.value" :label="it.label" :value="it.value"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" :loading="isCommit" @click="submitForm">确 定</el-button>
			</span>
		</el-dialog>
		<div style="width: 100%; float: right">
			<el-pagination
				class="fr"
				style="margin: 20px 0"
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="pagenation.pageNo"
				:page-sizes="[20, 50, 100, 200, 300, 400]"
				:page-size="pagenation.pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="pagenation.totalNum"
			></el-pagination>
		</div>
	</div>
</template>

<script>
import { getPositiveList } from '@/api/workbench';
import { getPositionLevelList } from '../../api/staffSystem';
import { getApprovalTypeOnce } from '@/api/requestOnce';
export default {
	name: 'reward',
	components: {},
	computed: {
		applyTypeList() {
			return this.$store.state.approval?.applyTypeList || [];
		}
	},
	props: {
		index: { type: String, default: () => '' },
		pageName: {}
	},
	data() {
		return {
			rules: {
				turnPositiveTime: [{ required: true, message: '请选择', trigger: 'blur' }],
				status: [{ required: true, message: '请选择', trigger: 'change' }]
			},
			// 分页参数
			pagenation: {
				pageNo: 1,
				pageSize: 20,
				totalNum: 0
			},
			// 搜索条件对象
			params: {
				name: '',
				office: '',
				officeName: '',
				entryTimeStart: '',
				status: '',
				entryTimeEnd: '',
				pageNo: 1,
				pageSize: 20
			},
			tableData: [],
			statusoptions: [],
			tableCols: [
				// {name: '序号',values:'id'},
				{ name: '姓名', values: 'name' },
				{ name: '工号', values: 'jobNumber' },
				{ name: '手机号码', values: 'phoneNumber' },
				{ name: '员工性质', values: 'hireFormName' },
				{ name: '部门', values: 'orgName' },
				{ name: '职位', values: 'officeName' },
				{ name: '入职日期', values: 'entryTime' },
				{ name: '试用期', values: 'probationPeriod' },
				{ name: '转正日期', values: 'turnPositiveTime' },
				{ name: '转正状态', values: 'statusName' },
				{ name: '审批状态', values: 'approvalStatusName' }
			],
			labelPosition: 'right',
			rowId: 0,
			loadding: false,
			positionOpt: [],
			commands: [
				{
					label: '详情',
					value: '1'
				}
			],
			isCommit: false,
			title: '',
			formModel: {},
			codeList: [],
			dialogVisible: false,
			statusOpts: [
				{ label: '未转正', value: '1' },
				{ label: '已转正', value: '2' },
				{ label: '离职', value: '3' },
				{ label: '延迟转正', value: '4' }
			]
		};
	},
	created() {
		this.statusoptions = this.$store.state.app.dict.filter((item) => {
			if (item.dictName != '离职' && item.groupId == 'staffStatus') {
				return item;
			}
		});
	},
	mounted() {
		this.$nextTick(() => {
			this.handleSearch();
			// 审批类型列表
			getApprovalTypeOnce();
		});
		// this.getPositions();
	},
	methods: {
		// 排序
		indexMethod(index) {
			return index + (this.pagenation.pageNo - 1) * this.pagenation.pageSize + 1;
		},
		getPositions() {
			getPositionLevelList({
				pageNo: 1,
				pageSize: 2000
			}).then((res) => {
				if (res._responseStatusCode === 0) {
					this.positionOpt = res.list;
				}
			});
		},
		startChange(val) {
			this.params.entryTimeStart = `${val} 00:00:00`;
		},
		endChange(val) {
			this.params.entryTimeEnd = `${val} 23:59:59`;
		},
		handleSearch() {
			this.loadding = true;
			const params = {
				request: this.params
			};

			getPositiveList(params)
				.then((result) => {
					this.tableData = result;
					this.pagenation.totalNum = result.totalNum;
					this.loadding = false;
				})
				.catch(() => {});
		},
		handleSizeChange(val) {
			this.pagenation.pageSize = val;
			this.params.pageSize = val;
			this.handleSearch();
		},
		handleCurrentChange(val) {
			this.pagenation.pageNo = val;
			this.params.pageNo = val;
			this.handleSearch();
		},
		rowClick(row) {
			this.rowId = row.id;
			// if (row.status === '2') {
			// this.$router.push({
			// 	path: '/positive_info',
			// 	query: {
			// 		id: this.rowId,
			// 		type: row.positiveType
			// 	}
			// });
			if (row.mainId) {
				this.$router.push({
					path: '/staff/approval_details',
					query: {
						id: row.mainId,
						staffId: row.id,
						queryType: 3,
						approvalTypeName: '转正',
						subType: 1
					}
				});
			}

			// }
		},
		submitForm() {
			this.$refs.configForm.validate((valid) => {
				if (valid) {
					this.isCommit = true;
					const { status, id, turnPositiveTime } = this.formModel;

					Promise.all([
						this.$axios.post('/v1/org/positive/updateStatus', { request: { id, status } }),
						this.$axios.post('/v1/org/positive/updateTime', { request: { id, turnPositiveTime } })
					]).then(() => {
						this.isCommit = false;
						this.dialogVisible = false;
						this.handleSearch();
						this.formModel = {};
					});
				} else {
					return false;
				}
			});
		},
		handleEidt(e, row) {
			this.formModel = { ...row };
			this.dialogVisible = true;
		},
		statusChange(e) {
			this.formModel.statusName = this.statusOpts.find((it) => it.value === e)?.label;
		},
		approvalSet() {
			const data = this.applyTypeList.find(({ subType }) => subType === 1);

			if (data) {
				const { subType, approvalId } = data;

				this.$router.push({
					path: '/staff/approval_apply?mode=申请审核',
					query: {
						subType,
						approvalId
					}
				});
			}
		}
	}
};
</script>
<style lang="scss" scoped>
.container {
	display: flex;
	flex-wrap: wrap;
}
.toolBox {
	width: 100%;
	margin-bottom: 10px;
	div {
		float: right;
	}
}
</style>
<style lang="scss">
.textarea {
	width: 100%;
	.el-form-item__content {
		width: 80%;
	}
}
</style>
